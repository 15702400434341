import React from 'react'
import 'twin.macro'
import ParkInfomation from '../organisms/ParkInfomation'

const ParkPageLayout: React.FC = () => {
  return (
    <>
      <ParkInfomation />
    </>
  )
}

export default ParkPageLayout
