import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image'
import tw, { styled } from 'twin.macro'
import ReactMarkdown from 'react-markdown'
import Wrapper from '../atoms/util/Wrapper'
import { mediaQuery } from '@/shared/cssValues'

const ImageGrid = styled.div`
  display: grid;
  margin-top: 26px;
  ${mediaQuery({
    gridTemplateColumns: ['1fr 1fr', '1fr 1fr', '1fr 1fr', '2.4fr 1fr'],
    gap: ['10px', '10px', '10px', '40px'],
  })}
`

const LargeImage = styled.div`
  ${mediaQuery({
    gridRowStart: ['1', '1', '1', '1'],
    gridRowEnd: ['2', '2', '2', '3'],
    gridColumnStart: ['1', '1', '1', '1'],
    gridColumnEnd: ['3', '3', '3', '2'],
  })}
`

const TableGlid = styled.div`
  display: grid;
  ${mediaQuery({
    gridTemplateColumns: ['1fr', '1fr', '1fr', '16.722% 1fr'],
    gap: ['0px', '0px', '0px', '40px'],
  })}
`

const TableTitle = tw`lg:border-r-black lg:border-r height[max-content] lg:font-normal font-bold`
const TableText = tw`lg:pl-7`

const ParkInfomation: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "parks" } } }
        sort: { fields: frontmatter___id, order: ASC }
      ) {
        edges {
          node {
            frontmatter {
              title
              name_short
              color
              description2
              address
              access
              summary
              contact
              hp
              event
              image1 {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              image2 {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              image3 {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }
    }
  `).allMarkdownRemark.edges.map((edge) => edge.node.frontmatter)
  return (
    <section tw="bg-white lg:pt-24 pt-14 lg:pb-80 pb-56">
      <Wrapper>
        <h2 tw="font-en lg:text-6xl text-3xl text-center">
          PARK & STREET INFOMATION
        </h2>
        <p tw="lg:mt-6 mt-3.5 lg:text-2xl text-sm text-center">
          公園・道路情報一覧
        </p>
        {data.map((park) => (
          <div key={park.name_short}>
            <div tw="lg:mt-20 mt-10 lg:text-lg lg:leading-loose text-base leading-loose">
              <h3 tw="lg:text-3xl text-xl font-en">{park.name_short}</h3>
              <div
                tw="lg:w-20 w-11 h-2 lg:mt-5 mt-2"
                css={{ backgroundColor: park.color }}
              ></div>
              <ImageGrid>
                <LargeImage>
                  <GatsbyImage image={getImage(park.image1)} alt={park.title} />
                </LargeImage>
                <GatsbyImage image={getImage(park.image2)} alt={park.title} />
                <GatsbyImage image={getImage(park.image3)} alt={park.title} />
              </ImageGrid>
            </div>
            {/* ここ治す */}
            <p tw="lg:text-lg text-base lg:pt-10 pt-8 lg:leading-loose">
              {park.description2}
            </p>
            <div tw="lg:text-lg text-base lg:leading-loose lg:pt-16 pt-10">
              <TableGlid>
                <p css={TableTitle}>名称</p>
                <p css={TableText}>{park.title}</p>
                <p css={TableTitle}>住所</p>
                <p css={TableText}>{park.address}</p>
                <p css={TableTitle}>アクセス</p>
                <div css={TableText}>
                  <ReactMarkdown children={park.access} />
                </div>
                <p css={TableTitle}>概要</p>
                <div css={TableText}>
                  <ReactMarkdown children={park.summary} />
                </div>
                <p css={TableTitle} tw="lg:mt-0 mt-5">
                  お問合せ先
                </p>
                <a css={TableText} href={`tel:${park.contact}`}>
                  {park.contact}
                </a>
                <p css={TableTitle} tw="lg:mt-0 mt-5">
                  公式HP
                </p>
                <a
                  css={TableText}
                  href={park.hp}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {park.hp}
                </a>
                <p css={TableTitle} tw="lg:mt-0 mt-5">
                  施設イベント情報
                </p>
                <a
                  css={TableText}
                  href={park.event}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {park.event}
                </a>
              </TableGlid>
            </div>
            <a
              href={park.event}
              target="_blank"
              rel="noopener noreferrer"
              tw="duration-300 text-white lg:text-2xl text-lg block text-center w-full lg:py-7 py-6 mt-24 rounded-lg"
              css={{
                backgroundColor: park.color,
                '&:hover': {
                  backgroundColor: '#FFFFFF',
                  color: park.color,
                  border: `1px solid ${park.color}`,
                },
              }}
            >
              施設のイベント情報はこちらから
            </a>
          </div>
        ))}
      </Wrapper>
    </section>
  )
}

export default ParkInfomation
