import React from 'react'
import { PageProps } from 'gatsby'
import Layout from '@/shared/globalStyle'
import ParkPageLayout from '@/components/templates/ParkPageLayout'
import SEO from '@/shared/seo'

const Park: React.FC<PageProps> = ({ path }) => (
  <Layout path={path}>
    <SEO title="PARK INFOMATION" lang="ja" />
    <ParkPageLayout />
  </Layout>
)

export default Park
